export default {
	取消: "Cancel",
	订单记录: "Order record",
	被接受: "Accepted",
	完成: "Completed",
	超时: "Timeout",
	成功: "Success",
	订单号: "Order number",
	订单总额: "Total amount of order",
	会员价: "Member price",
	复制链接以查看项目: "Copy link to view project",
	基金详情: "Fund details",
	账户变更类型: "Account change type",
	账户变更金额: "Account change amount",
	任务收入: "Task income",
	匹配任务: "Matched task",
	余额: "Balance",
	平均: "Average",
	昨天: "Yesterday",
	今天: "Today",
	本周: "This week",
	本月: "This month",
	总额: "Total amount",
	工作时间: "Working hours",
	已完成的订单: "Completed orders",
	获取产品: "Get products",
	警告: "Warning",
	请在订单中选择要提交的产品: "Please select the products to submit in the order",
	去下单: "Go to place an order",
	个人中心: "Personal center",
	详情: "Details",
	提款: "Withdrawal",
	充值: "Recharge",
	分享: "Share",
	邀请码: "Invitation code",
	拉兹商城: "Laz Mall",
	拉兹顶: "Laz Top",
	及以下: "₱99 and below",
	来赞达妈妈: "Come and praise Mom",
	优惠券: "Coupon",
	拉兹洛奇: "Lazlochy",
	拉兹美人: "Laz Beauty",
	贵宾奖金: "VIP bonus",
	现任会员收取佣金: "Current members receive commissions",
	重设密码: "Reset password",
	提现密码: "Withdrawal password",
	绑定账户: "Bind account",
	基金详情: "Fund details",
	我的团队: "My team",
	服务: "Service",
	关于我们: "About us",
	登出: "Log out",
	比其他地方便宜: "Cheaper than other places",
	菲律宾最大的中介电子商务平台: "The largest intermediary e-commerce platform in the Philippines",
	太平洋网上商城: "America-mall",
	请填写您的手机号码: "Please fill in your phone number",
	请输入登录密码: "Please enter your login password",
	请输入确认密码: "Please enter your confirmation password",
	请填写邀请码: "Please fill in the invitation code",
	联系客服: "Contact customer service",
	登录: "Log in",
	登记: "Register",
	忘记密码: "Forgot password",
	服务: "Service",
	电报: "Telegram",
	'复制账号，去telegram添加客服。如果您忘记密码，请联系客服': "Copy the account and add customer service on Telegram. If you forgot your password, please contact customer service",
	复制: "Copy",
	绑定账户: "Bind account",
	请仔细核对并填写信息: "Please carefully check and fill in the information",
	Gcash名称: "Gcash name",
	请输入Gcash名称: "Please enter Gcash name",
	现金账户: "Cash account",
	请输入Gcash账户: "Please enter Gcash account",
	绑定账户: "Bind account",
	警告: "Warning",
	'请在账户绑定中填写真实账户信息并核对。 如果您输入的信息不正确或虚假， 我们将不对由此造成的损失负责': "Please fill in the real account information and verify it in the account binding. We will not be responsible for any losses caused by incorrect or false information entered by you.",
	登录密码: "Login password",
	'如果您忘记登录密码， 可以联系客服修改': "If you forgot your login password, you can contact customer service to modify it",
	原始登录密码: "Original login password",
	请输入原登录密码: "Please enter the original login password",
	新的登录密码: "New login password",
	请输入新的登录密码确认: "Please enter the new login password for confirmation",
	确认: "Confirm",
	我的团队: "My team",
	人们: "People",
	邀请更多好人获得高额利润: "Invite more good people to get high profits",
	今天团队充值: "Team recharge today",
	今日团队提现: "Team withdrawal today",
	昨天团队充值: "Team recharge yesterday",
	昨天团队提现: "Team withdrawal yesterday",
	本月团队充值: "Team recharge this month",
	本月团队提现: "Team withdrawal this month",
	没有数据: "No data",
	支付密码: "Payment password",
	登录密码: "Login password",
	请输入登录密码以进行验证: "Please enter the login password for verification",
	支付密码必须为数字且不能超过6位: "The payment password must be numeric and cannot exceed 6 digits",
	充值金额: "Recharge amount",
	请输入充值金额: "Please enter the recharge amount",
	请选择付款方式: "Please select the payment method",
	现金支付: "Cash payment",
	充值步骤: "Recharge steps",
	'填写您要充值的金，然后点击确认': "Fill in the amount you want to recharge, then click confirm",
	'填与您稍后将用于转账的 GCASH': "Fill in the GCASH that you will use for transfer later",
	'点击复制我们的 GCASH 并放入应用程序': "Click to copy our GCASH and put it in the application",
	'转账您在步骤D 中填写的金额如果一分钟内没有到账， 请及时联系导师': "If the amount you filled in step D for transfer does not arrive within one minute, please contact the mentor in time",
	推荐您的链接并开始赚钱: "Recommend your link and start earning money",
	邀请您的联系人或好友获得奖励: "Invite your contacts or friends to get rewards",
	分享: "Share",
	平衡: "Balance",
	最低提款金额: "Minimum withdrawal amount",
	提款方式: "Withdrawal method",
	银行卡: "Bank card",
	提款金额: "Withdrawal amount",
	请输入提款金额: "Please enter the withdrawal amount",
	支付密码: "Payment password",
	请输入提现密码: "Please enter the withdrawal password",
	退出: "Exit",
	记录: "Records",
	如果您忘记密码: "If you forgot your password, you can go to the payment password to modify it. The withdrawal amount must be an integer.",

	// add 
	两次密码不一致: "Passwords do not match",
	帐号已存在: "Account already exists",
	注册成功: "Registration successful",
	帐号不已存在: "Account does not exist",
	密码错误: "Incorrect password",
	请输入登录帐号: "Please enter login account",
	登录失效: "Login expired.",
	请登录: "Please log in",
	收益: "Earnings",
	余额: "Balance",
	请输入新的支付密码: "Please enter a new payment password",
	修改请联系客服: "For modification, please contact customer service",
	复制成功: "Copy successful",
	关于我们: "About us",
	支付密码错误: "Incorrect payment password",
	余额不足: "Insufficient balance",
	申请成功: "Application successful",
	不足最小提现: "Below minimum withdrawal amount",
	提现金额请输入整数: "Please enter an integer for withdrawal amount",
	请先绑定提现帐号: "Please bind withdrawal account first",
	提现: "Withdrawal",
	金额类型: "Amount type",
	状态: "Status",
	时间: "Time",
	待审核: "Pending review",
	失败: "Failed",
	成功: "Successful",
	金额: "Amount",
	账户异常无法提现: "Account exception, unable to withdraw",
	匹配中: "Matching in progress",
	"帐号异常，请联系客服": "Account exception, please contact customer service",
	佣金: "Commission",
	订单支出: "Order expenditure",
	订单收入: "Order income",
	充值收入: "Recharge income",
	暂无抢单资格: "No qualification for grabbing orders at the moment",
	推广收入: "Promotion income",
	支付成功: "Payment successful",
	抢单提示: "When a user matches an order, the platform will synchronize the order information to Shopee and Lazada stores. Users must make timely payments to complete the order purchase to avoid supervision by Shopee and Lazada platforms. If losses are caused by delayed payment, our store merchants have the right to demand corresponding work penalties! The product order system will automatically generate a virtual shipping address for each payment and purchase, so users will not be able to receive the purchased products. The payment and work commission will be immediately settled by the merchant within 3-5 seconds and automatically returned to the user's wallet. System Al automatically matches product orders, and A will randomly select products based on the balance of the system inventory. If the user encounters a product whose price exceeds your wallet balance, please make up for the balance.",
	累计奖励: "Accumulated rewards",
	待完成: "To be completed",
	待支付: "To be paid",
	"如果您忘记登录密码，可以联系客服修改": "If you forget your login password, you can contact customer service to modify it",
	退出成功: "Exit successfully",
	订单匹配中: "Matching orders...",
	单价: "Price",
	"转账转账充值后如果一分钟内没有到账，请及时联系导师": "If the account is not received within one minute after transfer and recharge, please contact your supervisor promptly",
	"请在账户绑定中填写真实账户信息并核对。如果您输入的信息不正确或虚假，我们将不对由此造成的损失负责": "Please fill in the real account information in the account binding and verify it. If the information you enter is incorrect or false, we will not be responsible for any losses caused by this",
	修改成功: "Successfully modified",
	邀请码不存在: "Invitation code does not exist",
	账户余额不足: "Insufficient account balance",
	请完成任务后进行提现: "Please withdraw after completing the task",
	订单未完成: "Order not completed",
	签到: "Sign in",
	日: "Day",
	收益: "Earnings",
	一: "One",
	二: "Two",
	三: "Three",
	四: "Four",
	五: "Five",
	六: "Six",
	今天的签到奖励: "Today’s sign-in reward",
	现金: "Cash",
	直接发放到帐户余额: "Directly credited to account balance",
	连续签到奖励: "Consecutive sign-in reward",
	天: "Days",
	签到奖励:"Sign-in reward",
	今日已签到:"Signed in today",
	签到成功:"Successfully signed in",
	请勿重复支付:"Do not make duplicate payments",
	充值收入:"Recharge income",
	请重新登录:"Please log in again",
	"余额不足100PHP":"Less than 100PHP balance",
	请选择支付类型:"Please select payment type",
	线下转款:"BANK",
	请转账后在点击提交按钮:"After the transfer,please send the transfer diagram to the instructor",
	开户名:"Account name",
	开户银行:"Bank Name",
	银行账号:"Bank account",
	等待审核:"Waiting for Review",
	
}
