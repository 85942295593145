export default{
	取消:"取消",
	订单记录:"订单记录",
	被接受:"被接受",
	完成:"完成",
	超时:"超时",
	成功:"成功",
	订单号:"订单号",
	订单总额:"订单总额",
	会员价:"会员价",
	复制链接以查看项目:"复制链接以查看项目",
	基金详情:"基金详情",
	账户变更类型:"账户变更类型",
	账户变更金额:"账户变更金额",
	任务收入:"任务收入",
	匹配任务:"匹配任务",
	余额:"余额",
	平均:"平均",
	昨天:"昨天",
	今天:"今天",
	本周:"本周",
	本月:"本月",
	总额:"总额",
	工作时间:"工作时间",
	已完成的订单:"已完成的订单",
	获取产品:"获取产品",
	警告:"警告",
	请在订单中选择要提交的产品:"请在订单中选择要提交的产品",
	去下单:"去下单",
	个人中心:"个人中心",
	详情:"详情",
	提款:"提款",
	充值:"充值",
	分享:"分享",
	邀请码:"邀请码",
	拉兹商城:"拉兹商城",
	拉兹顶:"拉兹顶",
	及以下:"₱99 及以下",
	来赞达妈妈:"来赞达妈妈",
	优惠券:"优惠券",
	拉兹洛奇:"拉兹洛奇",
	拉兹美人:"拉兹美人",
	贵宾奖金:"贵宾奖金",
	现任会员收取佣金:"现任会员收取佣金",
	重设密码:"重设密码",
	提现密码:"提现密码",
	绑定账户:"绑定账户",
	基金详情:"基金详情",
	我的团队:"我的团队",
	服务:"服务",
	关于我们:"关于我们",
	登出:"登出",
	比其他地方便宜:"比其他地方便宜",
	菲律宾最大的中介电子商务平台:"菲律宾最大的中介电子商务平台",
	太平洋网上商城:"太平洋网上商城",
	请填写您的手机号码:"请填写您的手机号码",
	请输入登录密码:"请输入登录密码",
	请输入确认密码:"请输入确认密码",
	请填写邀请码:"请填写邀请码",
	联系客服:"联系客服",
	登录:"登录",
	登记:"登记",
	忘记密码:"忘记密码",
	服务:"服务",
	电报:"电报",
	'复制账号，去telegram添加客服。如果您忘记密码，请联系客服':"复制账号，去telegram添加客服。如果您忘记密码，请联系客服",
	复制:"复制",
	绑定账户:"绑定账户",
	请仔细核对并填写信息:"请仔细核对并填写信息",
	Gcash名称:"Gcash名称",
	请输入Gcash名称:"请输入Gcash名称",
	现金账户:"现金账户",
	请输入Gcash账户:"请输入Gcash账户",
	绑定账户:"绑定账户",
	警告:"警告",
	'请在账户绑定中填写真实账户信息并核对。如果您输入的信息不正确或虚假，我们将不对由此造成的损失负责':"请在账户绑定中填写真实账户信息并核对。如果您输入的信息不正确或虚假，我们将不对由此造成的损失负责",
	登录密码:"登录密码",
	'如果您忘记登录密码，可以联系客服修改':"如果您忘记登录密码，可以联系客服修改",
	原始登录密码:"原始登录密码",
	请输入原登录密码:"请输入原登录密码",
	新的登录密码:"新的登录密码",
	请输入新的登录密码确认:"请输入新的登录密码确认",
	确认:"确认",
	我的团队:"我的团队",
	人们:"人们",
	邀请更多好人获得高额利润:"邀请更多好人获得高额利润",
	今天团队充值:"今天团队充值",
	今日团队提现:"今日团队提现",
	昨天团队充值:"昨天团队充值",
	昨天团队提现:"昨天团队提现",
	本月团队充值:"本月团队充值",
	本月团队提现:"本月团队提现",
	没有数据:"没有数据",
	支付密码:"支付密码",
	登录密码:"登录密码",
	请输入登录密码以进行验证:"请输入登录密码以进行验证",
	支付密码必须为数字且不能超过6位:"支付密码必须为数字且不能超过6位",
	充值金额:"充值金额",
	请输入充值金额:"请输入充值金额",
	请选择付款方式:"请选择付款方式",
	现金支付:"现金支付",
	充值步骤:"充值步骤",
	'填写您要充值的金，然后点击确认':"填写您要充值的金，然后点击确认",
	'填与您稍后将用于转账的 GCASH':"填与您稍后将用于转账的 GCASH",
	'点击复制我们的 GCASH 并放入应用程序':"点击复制我们的 GCASH 并放入应用程序",
	'转账您在步骤D 中填写的金额如果一分钟内没有到账，请及时联系导师':"转账您在步骤D 中填写的金额如果一分钟内没有到账，请及时联系导师",
	推荐您的链接并开始赚钱:"推荐您的链接并开始赚钱",
	邀请您的联系人或好友获得奖励:"邀请您的联系人或好友获得奖励",
	分享:"分享",
	
	最低提款金额:"最低提款金额",
	提款方式:"提款方式",
	银行卡:"银行卡",
	提款金额:"提款金额",
	请输入提款金额:"请输入提款金额",
	支付密码:"支付密码",
	请输入提现密码:"请输入提现密码",
	退出:"退出",
	记录:"记录",
	如果您忘记密码:"如果您忘记密码，可以前往支付密码进行修改。提款金额必须为整数。",
	
	//add
	两次密码不一致:"两次密码不一致",
	帐号已存在:"帐号已存在",
	注册成功:"注册成功",
	帐号不已存在:"帐号不已存在",
	密码错误:"密码错误",
	请输入登录帐号:"请输入登录帐号",
	登录失效:"登录失效",
	请登录:"请登录",
	收益:"收益",
	余额:"余额",
	请输入新的支付密码:"请输入新的支付密码",
	修改请联系客服:"修改请联系客服",
	复制成功:"复制成功",
	关于我们:"关于我们",
	支付密码错误:"支付密码错误",
	余额不足:"余额不足",
	申请成功:"申请成功",
	不足最小提现:"不足最小提现",
	提现金额请输入整数:"提现金额请输入整数",
	请先绑定提现帐号:"请先绑定提现帐号",
	提现:"提现",
	金额类型:"金额类型",
	状态:"状态",
	时间:"时间",
	待审核:"待审核",
	失败:"失败",
	成功:"成功",
	金额:"金额",
	抢单提示:"当用户匹配到一个订单后，平台会将订单信息同步到shopee和lazada店铺，用户必须及时付款完成订单购买，以避免shopee和lazada平台的监督，如因延迟付款而造成的损失，本店商户有权要求相应的工作处罚!产品订单系统在每次付款购买时会自动生成一个虚拟收货地址因此用户将无法收到所购买的商品。款和工作佣金会在3-5秒内立即由商家结算，并自动返回到用户的钱包中。系统Al自动匹配产品订单，A[将根据系统库存的余额随机选择产品。如果用户遇到的产品，其价格超过您的钱包余额，请弥补余额。",
	账户异常无法提现:"账户异常无法提现",
	匹配中:"匹配中",
	"帐号异常，请联系客服":"帐号异常，请联系客服",
	佣金:"佣金",
	订单支出:"订单支出",
	订单收入:"订单收入",
	充值收入:"充值收入",
	暂无抢单资格:"暂无抢单资格",
	推广收入:"推广收入",
	支付成功:"支付成功",
	签到:"签到",
	日:"日",
	收益:"收益",
	一:"一",
	二:"二",
	三:"三",
	四:"四",
	五:"五",
	六:"六",
	今天的签到奖励:"今天的签到奖励",
	现金:"现金",
	直接发放到帐户余额:"直接发放到帐户余额",
	连续签到奖励:"连续签到奖励",
	天:"天",
	
	签到奖励:"签到奖励",
	今日已签到:"今日已签到",
	签到成功:"签到成功",
	请勿重复支付:"请勿重复支付",
	充值收入:"充值收入",
	请重新登录:"请重新登录",
	"余额不足100PHP":"余额不足100PHP",
	请选择支付类型:"请选择支付类型",
	线下转款:"线下转款",
	请转账后在点击提交按钮:"请转账后在点击提交按钮",
	开户名:"开户名",
	开户银行:"开户银行",
	银行账号:"银行账号",
	等待审核:"等待审核",
	
}

