import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import i18n  from './i18n/index'
import 'vant/lib/index.css';
import { Lazyload } from 'vant';

Vue.use(Lazyload);

Vue.use(Vant);

Vue.config.productionTip = false
Vue.prototype.back = ()=>{
	router.go(-1)
}

new Vue({
  router,
  i18n,// 挂载
  render: h => h(App)
}).$mount('#app')
