import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: ()=>import('../views/login.vue')
  },
  {
    path: '/withdrawal',
    name: 'withdrawal',
    component: ()=>import('../views/withdrawal.vue')
  },
  {
    path: '/histroy',
    name: 'histroy',
    component: ()=>import('../views/histroy.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: ()=>import('../views/about.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: ()=>import('../views/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: ()=>import('../views/register.vue')
  },
  {
    path: '/fund',
    name: 'fund',
    component: ()=>import('../views/fund.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: ()=>import('../views/about.vue')
  },
  {
    path: '/index',
    name: 'regindexister',
    component: ()=>import('../views/index.vue')
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: ()=>import('../views/recharge.vue')
  },
  {
    path: '/changePd',
    name: 'changePd',
    component: ()=>import('../views/changePd.vue')
  },
  {
    path: '/paymentPd',
    name: 'paymentPd',
    component: ()=>import('../views/paymentPd.vue')
  },
  {
    path: '/bindAccount',
    name: 'bindAccount',
    component: ()=>import('../views/bindAccount.vue')
  },
  {
    path: '/myTeam',
    name: 'myTeam',
    component: ()=>import('../views/myTeam.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: ()=>import('../views/service.vue')
  },
  {
    path: '/share',
    name: 'share',
    component: ()=>import('../views/share.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: ()=>import('../views/user.vue')
  },
  {
    path: '/task',
    name: 'task',
    component: ()=>import('../views/task.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: ()=>import('../views/cart.vue')
  },
  {
    path: '/signIn',
    name: 'signIn',
    component: ()=>import('../views/signIn.vue')
  },
  {
    path: '/paybank',
    name: 'paybank',
    component: ()=>import('../views/paybank.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
